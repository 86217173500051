import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {
  GoogleAuthProvider,
  signInWithPopup,
  getRedirectResult,
  signInWithRedirect,
} from "firebase/auth";

let firebaseConfig = {};

if (process.env.VUE_APP_NODE_ENV === "production") {
  firebaseConfig = {
    apiKey: process.env.VUE_APP_PROD_FB_API_KEY,
    authDomain: process.env.VUE_APP_PROD_FB_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROD_FB_PROJECTID,
    storageBucket: process.env.VUE_APP_PROD_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_PROD_APP_FB_MESSAGING_SENDERID,
    appId: process.env.VUE_APP_PROD_FB_APP_ID,
    measurementId: process.env.VUE_APP_PROD_FB_MEASUREMENT_ID,
  };
}
if (process.env.VUE_APP_NODE_ENV === "staging") {
  firebaseConfig = {
    apiKey: process.env.VUE_APP_STAG_FB_API_KEY,
    authDomain: process.env.VUE_APP_STAG_FB_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_STAG_FB_PROJECTID,
    storageBucket: process.env.VUE_APP_STAG_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_STAG_FB_MESSAGING_SENDERID,
    appId: process.env.VUE_APP_STAG_FB_APP_ID,
    measurementId: process.env.VUE_APP_STAG_FB_MEASUREMENT_ID,
  };
}

// init firebase
firebase.initializeApp(firebaseConfig);

// init services

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const provider = new GoogleAuthProvider();

//timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
  db,
  auth,
  storage,
  provider,
  timestamp,
  GoogleAuthProvider,
  signInWithPopup,
  getRedirectResult,
  signInWithRedirect,
};
