import { ref, watchEffect } from "vue";
import { db } from "../firebase/config";

const getCollection = (collection, query, order) => {
  const documents = ref(null);
  const error = ref(null);
  const isPending = ref(false);
  let collectionRef = db.collection(collection);

  if (query) {
    collectionRef = collectionRef.where(...query);
  }

  if (order) {
    collectionRef = collectionRef.orderBy(order);
  }

  const unsub = collectionRef.onSnapshot(
    (snap) => {
      let results = [];
      isPending.value = true;
      snap.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });

      documents.value = results;
      error.value = null;
      isPending.value = false;
    },
    (err) => {
      console.log(err.message);
      documents.value = null;
      error.value = "could not fetch the data";
    }
  );

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { error, documents };
};

export default getCollection;
