import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { auth } from "./firebase/config";
import loader from "vue-ui-preloader";
import "./style.css";
import "mosha-vue-toastify/dist/style.css";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faGear,
  faIdBadge,
  faX,
  faArrowRightFromBracket,
  faMagnifyingGlass,
  faCalendarDays,
  faXmark,
  faCheck,
  faArrowUpRightFromSquare,
  faTrash,
  faArrowLeft,
  faRotate,
  faClipboard,
  faClipboardList,
  faChevronDown,
  faCheckCircle,
  faTriangleExclamation,
  faUser,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAddressBook,
  faGear,
  faIdBadge,
  faX,
  faArrowRightFromBracket,
  faMagnifyingGlass,
  faCalendarDays,
  faXmark,
  faCheck,
  faArrowUpRightFromSquare,
  faTrash,
  faArrowLeft,
  faRotate,
  faClipboardList,
  faChevronDown,
  faCheckCircle,
  faChevronDown,
  faTriangleExclamation,
  faCircleUser,
  faArrowUpRightFromSquare
);

import FvSelectorIcon from "@/components/icons/FvSelectorIcon";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

let app;

auth.onAuthStateChanged((user) => {
  if (!app) {
    store.commit("auth/setAuth", user);
    app = createApp(App)
      .use(store)
      .use(router)
      .use(loader)
      .component("Switch", Switch)
      .component("SwitchGroup", SwitchGroup)
      .component("SwitchLabel", SwitchLabel)
      .component("font-awesome-icon", FontAwesomeIcon)
      .component("Datepicker", Datepicker)
      .component("Combobox", Combobox)
      .component("ComboboxButton", ComboboxButton)
      .component("ComboboxInput", ComboboxInput)
      .component("ComboboxLabel", ComboboxLabel)
      .component("ComboboxOption", ComboboxOption)
      .component("ComboboxOptions", ComboboxOptions)
      .component("Listbox", Listbox)
      .component("ListboxButton", ListboxButton)
      .component("ListboxLabel", ListboxLabel)
      .component("ListboxOption", ListboxOption)
      .component("ListboxOptions", ListboxOptions)
      .component("TransitionChild", TransitionChild)
      .component("TransitionRoot", TransitionRoot)
      .component("Menu", Menu)
      .component("MenuButton", MenuButton)
      .component("MenuItem", MenuItem)
      .component("MenuItems", MenuItems)
      .component("FvSelectorIcon", FvSelectorIcon)
      .component("Dialog", Dialog)
      .component("DialogPanel", DialogPanel)
      .component("DialogTitle", DialogTitle)
      .mount("#app");
  }
});
