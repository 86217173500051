import { createToast } from "mosha-vue-toastify";

const getErrorMessage = (error) => {
  console.log(error);

  let item = error?.message
    ? { type: null, message: error?.message, path: error?.cPath || null }
    : Array.isArray(error?.errors)
    ? {
        type: error?.errors[0]?.errorType,
        message: error?.errors[0]?.message,
        path:
          error?.errors[0]?.path?.length > 0 ? error?.errors[0]?.path[0] : null,
      }
    : Array.isArray(error?.error?.errors)
    ? {
        type: error?.error?.errors[0]?.errorType,
        message: error?.error?.errors[0]?.message,
      }
    : typeof error === "string"
    ? { type: null, message: error }
    : { type: null, message: "#ERROR" };

      item.path ?? error.cPath;

  return item;
};

const showToast = (type, data, options) => {
  try {
    // data => {title, item}

    if (["success", "error", "game"].includes(type)) {
      let title = "";
      let description = "";
      let toastBackgroundColor = "";
      let timeout = 5000;
      let typeT = "";

      if (type === "success") {
        toastBackgroundColor = "#0e7490";
        typeT = "success";

        title = data?.title;
        description = data?.message;
      }

      if (type === "game") {
        toastBackgroundColor = "#111827";
        typeT = "info";

        title = data?.title;
        description = data?.message;
      }

      if (type === "error") {
        let item = getErrorMessage(data?.message);

        if(!item.path && data.cPath) {
          item.path = data.cPath;
        }

        // title = data?.title || "There was error " + (item?.type ? ` (${item?.type})` : data?.type ? ` (${data?.type})` : "");

        title = "";

        if (data?.title) {
          title = data?.title;
        } else if (data?.message?.title) {
          title = data?.message?.title;
        } else {
          title =
            "Щось пішло не так " +
            (item?.path
              ? `в ${item.path}`
              : item?.type
              ? ` (${item?.type})`
              : data?.type
              ? ` (${data?.type})`
              : "");
        }

        description = item?.message;

        toastBackgroundColor = "#ef4444";
        typeT = "warning";
        timeout = calculateTimeToRead(description);
      }

      createToast(
        {
          title: title,
          description: description,
        },
        {
          timeout: calculateTimeToRead(description),
          toastBackgroundColor: toastBackgroundColor,
          transition: "zoom",
          type: typeT,
          showIcon: true,
          ...(options ? { ...options } : {}),
        }
      );
    }
  } catch (err) {
    createToast(
      {
        title: "Error toast",
        description: err.message,
      },
      {
        timeout: timeout,
        toastBackgroundColor: "#ef4444",
        transition: "zoom",
        type: "danger",
        showIcon: true,
      }
    );
  }
};

const calculateTimeToRead = (text) => {
  
  if(!text) return 3000;
  
  const wordsPerMinute = 250;

  const words = text?.length;

  let totalSeconds = (words / wordsPerMinute) * 60 * 1000;

  if(totalSeconds< 3000) totalSeconds = 3000;

  return totalSeconds || 5000;
};

export { getErrorMessage, showToast };
