<template>
  <div id="nav">
    <Navbar></Navbar>
  </div>
  <div
    v-if="$store.getters['auth/getAuth'] && !$store.getters['auth/getIsAdmin']"
    class="border-t-4 border-red-400 bg-red-100 p-4 m-2"
  >
    <div class="flex justify-end">
      <div class="flex-shrink-0">
        <!-- <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" /> -->
        <font-awesome-icon
          class="h-5 text-red-500"
          :icon="['fas', 'triangle-exclamation']"
        />
      </div>
      <div class="ml-3">
        <p class="text-sm text-yellow-700">
          Користувач: <strong>{{ $store.getters["auth/getAuth"]?.email }}</strong> не є
          адміністратором даного сайту !
        </p>
      </div>
    </div>
  </div>

  <main class="pb-5">
    <router-view />
  </main>
  <loader
    v-if="$store.getters.getIsLoading"
    object="#ff9633"
    color1="#ffffff"
    color2="#17fd3d"
    size="5"
    speed="2"
    bg="#343a40"
    objectbg="#999793"
    opacity="40"
    name="dots"
  ></loader>
</template>

<script>
import { onMounted, onUnmounted, watch, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { db, storage } from "@/firebase/config";

import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";
import Navbar from "@/components/Navbar.vue";
import getCollection from "@/composables/getCollection";

import { showToast } from "@/composables/showToast";
export default {
  components: { Navbar },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      rawAuthors: getCollection("RawAuthors", ["status", "==", "review"], null).documents,
      rawContent: getCollection("RawContent", ["status", "==", "review"], null).documents,
    });

    const { logout } = useLogout();
    const { user } = getUser();
    var unsubscribe = null;

    onMounted(async () => {
      try {
        if (user?.value?.uid) {
          unsubscribe = db
            .collection("Permissions")
            .doc(user?.value?.uid)
            .onSnapshot(
              (snapshot) => {
                store.commit("auth/setIsAdmin", snapshot.data()?.admin || false);
              },
              (error) => {
                console.log({ error });
                store.commit("auth/setIsAdmin", false);
              }
            );
        }
      } catch (error) {
        showToast("error", { message: error });
      }
    });

    watch(
      () => [state.rawAuthors, state.rawContent],
      () => {
        try {
          store.commit("setRawReviews", [
            ...(state.rawAuthor || []),
            ...(state.rawContent || []),
          ]);
        } catch (error) {
          showToast("error", { message: error });
        }
      }
    );

    watch(
      () => store.getters["auth/getAuth"],
      () => {
        try {
          let user = store.getters["auth/getAuth"];

          console.log({ user });

          if (store.getters["auth/getAuth"]) {
            unsubscribe = db
              .collection("Permissions")
              .doc(user.uid)
              .onSnapshot(
                (snapshot) => {
                  store.commit("auth/setIsAdmin", snapshot.data()?.admin || false);
                },
                (error) => {
                  store.commit("auth/setIsAdmin", false);
                }
              );
          } else {
            if (unsubscribe) unsubscribe();
            store.commit("auth/setIsAdmin", true);
          }
        } catch (error) {
          showToast("error", { message: error });
        }
      }
    );

    const logOutHandle = async () => {
      await logout();
      console.log("user logged out");
      router.push({ name: "Login" });
    };

    return { logOutHandle };
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-image: url("./assets/background.png");
  background-repeat: no-repeat;

  background-attachment: fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

input,
textarea,
select {
  width: 100%;
}

.form-details {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  color: white;
}

.f-input {
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 16px;
  /* margin-bottom: 10px; */
  border: 0;
  color: white;
}
.f-column {
  padding: 15px;
}

.slug-input {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  /* margin-bottom: 10px; */
  border: 0;
  color: white;
}

.c-header {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  text-align: center;
  align-items: center;
  /* padding-left: 30px; */
  /* padding-right: 10px; */
}
.c-header-title {
  color: white;
}

.image-create {
  display: flex;
  font-size: 3rem;
  height: 150px;
  background-image: url("./assets/image-create.png");
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  color: white;
  justify-content: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.c-btn {
  border-radius: 16px;
  font-weight: 600;
  width: 25%;
  /* background: rgb(131, 58, 180); */
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(67, 194, 249, 1) 0%,
    rgba(29, 253, 233, 1) 0%,
    rgba(148, 231, 220, 1) 100%
  );
}
.c-close {
  border: 0;
  border-radius: 9999px;
  padding: 0.5rem;
  background: rgba(117, 135, 175, 255);
  color: #fff;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

.c-container {
  /* display: flex; */
  background: rgba(0, 0, 0, 0.444);
  border-radius: 20px;
  min-height: 500px;
  max-width: 960px;
  margin: 0 auto;

  /* margin: 0 auto; */
}

.bg-form {
  background: rgba(0, 0, 0, 0.6);
}
.btn-form {
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(67, 194, 249, 1) 0%,
    rgba(29, 253, 233, 1) 0%,
    rgba(148, 231, 220, 1) 100%
  );
}
</style>
