<template>
  <header>
    <div class="header">
      <div class="h-image">
        <img alt="Vue logo" src="../assets/header-icon.png" />
      </div>
      <div v-if="user">
        <router-link
          v-if="$store.getters['auth/getIsAdmin']"
          v-for="it in navigation"
          :key="it?.name"
          :to="{ name: it?.href }"
          :title="it?.name"
          class="h-menu mx-1"
        >
          <!-- <font-awesome-icon class="h-menu" :icon="it?.icon" /> -->
          <span class="relative inline-block">
            <font-awesome-icon
              :class="[
                $route.meta.tag !== 'review' &&
                it?.href === 'Reviews' &&
                $store.getters.getRawRevies?.length
                  ? 'animate-bounce'
                  : '',
              ]"
              class="h-menu"
              :icon="it?.icon"
            />

            <!-- <span
              v-if="it?.href === 'Reviews' && $store.getters.getRawRevies?.length"
              class="absolute -top-1 left-1/2 -translate-x-1/2 h-1 w-1 rounded-full bg-red-400 ring-1 ring-white"
            /> -->
          </span>
        </router-link>
        <button class="h-menu mx-1" @click="logOutHandle" title="Вийти">
          <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { db, storage } from "@/firebase/config";
import getUser from "@/composables/getUser";

import useLogout from "@/composables/useLogout";
import { showToast } from "@/composables/showToast";

export default {
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const { logout } = useLogout();
    const store = useStore();

    const state = reactive({
      navigation: [
        {
          name: "Календар",
          href: "Calendar",
          icon: "fa-solid fa-calendar-days",
        },
        {
          name: "Автори",
          href: "Authors",
          icon: "fa-solid fa-id-badge",
        },
        {
          name: "Огляд",
          href: "Reviews",
          icon: "fa-solid fa-clipboard-list",
        },
      ],
    });

    onMounted(async () => {
      try {
      } catch (error) {
        showToast("error", { message: error });
      }
    });

    const logOutHandle = async () => {
      await logout();

      store.commit("auth/setAuth", null);

      router.push({ name: "Login" });
    };
    return { user, logOutHandle, ...toRefs(state) };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.h-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.h-menu {
  border: 0;
  background: transparent;
  color: lightblue;
  font-size: 30px;
}

.h-image img {
  width: 100%;
  height: 100%;
}
</style>
