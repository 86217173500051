import { createStore } from "vuex";
import { authModule } from "./authModule";

export default createStore({
  state: {
    isLoading: false,
    rawReviews: [],
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
    getRawRevies(state) {
      return state.rawReviews;
    },
  },
  mutations: {
    setIsLoading(state, data) {
      state.isLoading = data;
    },

    setRawReviews(state, data) {
      state.rawReviews = data;
    },
  },
  actions: {},
  modules: {
    auth: authModule,
  },
});
