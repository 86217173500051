export const authModule = {
  state: () => ({
    auth: null,
    isAdmin: true,
  }),
  mutations: {
    setAuth(state, data) {
      state.auth = data;
    },
    setIsAdmin(state, data) {
      state.isAdmin = data;
    },
  },
  getters: {
    getAuth(state) {
      return state.auth;
    },
    getIsAdmin(state) {
      return state.isAdmin;
    },
  },

  actions: {},
  namespaced: true,
};
