import { createRouter, createWebHistory } from "vue-router";
import { auth } from "../firebase/config";

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;

  if (from.meta.tag && to.meta.tag && from.meta.tag !== to.meta.tag) {
    sessionStorage.removeItem("filterReview");
  }

  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  //* Login
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/Login.vue"),
  },

  //* Authors
  {
    path: "/",
    name: "Authors",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/authors/"),
    beforeEnter: requireAuth,
    meta: { tag: "author" },
  },
  {
    path: "/author-create",
    name: "AuthorCreate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/authors/create"),
    beforeEnter: requireAuth,
    meta: { tag: "author" },
  },
  {
    path: "/author-edit/:id",
    name: "AuthorEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/authors/edit"),
    beforeEnter: requireAuth,
    meta: { tag: "author" },
    props: true,
  },

  //* Calendar
  {
    path: "/calendar",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/calendar/"),
    beforeEnter: requireAuth,
    meta: { tag: "calendar" },
  },

  //* Review
  {
    path: "/reviews",
    name: "Reviews",
    component: () => import(/* webpackChunkName: "about" */ "../views/review/"),
    beforeEnter: requireAuth,
    meta: {
      tag: "review",
    },
  },
  {
    path: "/review-edit/a/:rawAuthorId",
    name: "ReviewAuthorEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/review/edit"),
    beforeEnter: requireAuth,
    props: true,
    meta: {
      tag: "review",
    },
  },
  {
    path: "/review-edit/c/:rawContentId",
    name: "ReviewContentEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/review/edit"),
    beforeEnter: requireAuth,
    props: true,
    meta: {
      tag: "review",
    },
  },
];

if (location.hostname === "localhost") {
  routes.push({
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
